import axios from "axios";
import {
  notification,
} from 'antd';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('authDetails'));
    if (token?.access) {
      config.headers["Authorization"] = `Bearer ${token.access}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      return window.location.href = '/sign-in';
    }
    const msg = JSON.stringify(error?.response?.data || error?.response?.statusText || '');
    openNotification(`${error.response.status}: ${msg}`);
    return Promise.reject(error);
  }
);

const openNotification = (msg) => {
  notification.error({
    message: `Error`,
    description: msg,
    placement: 'top',
    duration: 0,
  });
};

export default instance;
