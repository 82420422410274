import * as React from "react";
import { format } from "date-fns";
import {
  getPhoneNos,
  getItemVariationNames,
  getSubTax,
  getFormattedAddress,
  transformOrderDetails,
} from "../helpers/Utils";

export const OrderReceipt = React.forwardRef((props, ref) => {
  const { order } = props;
  const { outlet } = order;
  const details = transformOrderDetails(JSON.parse(JSON.stringify(order)));

  return (
    <div className="print_wrapper" ref={ref}>
      {
        order?.id &&
        <div>
          <div className="print_header">
            <p className="outlet_name">{order.outlet.name}</p>
            <p>{outlet.business_name}</p>
            <p>{outlet.address.formatted_address}</p>
            <p>{getPhoneNos(outlet)}</p>
            {
              outlet.gst_no &&
              <p>GST: {outlet.gst_no}</p>
            }
            {
              Object.keys(outlet.business_registration_nos)
                .map(regKey => <p>{regKey}: {outlet.business_registration_nos[regKey]}</p>)
            }
          </div>
          <div className="divider"></div>
          {
            order.user &&
            <div>
              <p>Name: {order.user.first_name} {order.user.last_name}</p>
              {
                order.user.address &&
                <p>{getFormattedAddress(order.user.address)}</p>
              }
              <p>Tel#: {order.user.phone_number}</p>
              <p>M.O.P: { order.payment_type || 'Online' }</p>
            </div>
          }
          <div className="divider"></div>
          <div className="order_details">
            <p>No: #{order.id}</p>
            <p>{format(new Date(order.created_at), "HH:mm:ss")}</p>
            <p>{format(new Date(order.created_at), "dd/MM/yyyy")}</p>
          </div>
          {
            order.invoice_no &&
            <div className="order_details">
              <p>Bill No: #{order.invoice_no}</p>
            </div>
          }
          <div className="divider"></div>
          <div className="summary bold">
            <p style={{ width: "50%" }}>ITEM</p>
            <p style={{ textAlign: "right" }}>QTY</p>
            <p>PRICE</p>
          </div>
          {
            details.map((order, i) => {
              return (
                <div className="summary" key={i}>
                  <div className="item_details">
                    <p>{order.item_name}</p>
                    {
                      order.addons.map(addon => {
                        <p className="addons" key={addon.id}>{addon.description}</p>
                      })
                    }
                    <div className="variations">
                      {
                        getItemVariationNames(order.variations)
                          .map(variation => {
                            return <p className="variations" key={variation.id}>
                              <span>{variation.variation_name}</span>
                              {
                                variation?.quantity && variation.quantity > 1 &&
                                <span> X {variation.quantity}</span>
                              }
                            </p>
                          })
                      }
                    </div>
                  </div>
                  <p style={{ textAlign: "right" }}>X {order.quantity}</p>
                  <p><span className="rupee">₹</span>{order.item_price}</p>
                </div>
              );
            })
          }
          <div className="divider"></div>
          <div className="summary">
            <p>Item Sub Total</p>
            <p><span className="rupee">₹</span>{order.total}</p>
          </div>
          {
            order.discount !== '0.00' &&
            <div className="summary">
              <p>Discount</p>
              <p><span className="rupee">₹</span>{order.discount}</p>
            </div>
          }
          {
            order.packaging_charges !== '0.00' &&
            <div className="summary">
              <p>Packaging Charges</p>
              <p><span className="rupee">₹</span>{order.packaging_charges}</p>
            </div>
          }
          {
            order.tax !== '0.00' &&
            <>
              <div className="summary">
                <p>CGST</p>
                <p><span className="rupee">₹</span>{getSubTax(order.tax)}</p>
              </div>
              <div className="summary">
                <p>SGST</p>
                <p><span className="rupee">₹</span>{getSubTax(order.tax)}</p>
              </div>
            </>
          }
          {
            order.vat !== '0.00' &&
            <div className="summary">
              <p>VAT</p>
              <p><span className="rupee">₹</span>{order.vat}</p>
            </div>
          }
          {
            order.online_convenience_fee !== '0.00' &&
            <div className="summary">
              <p>Convenience Fee</p>
              <p><span className="rupee">₹</span>{order.online_convenience_fee}</p>
            </div>
          }
          {
            order.service_charges !== '0.00' &&
            <div className="summary">
              <p>Service Charges</p>
              <p><span className="rupee">₹</span>{order.service_charges}</p>
            </div>
          }
          {
            order.delivery_charges !== '0.00' &&
            <div className="summary">
              <p>Delivery Charges</p>
              <p><span className="rupee">₹</span>{order.delivery_charges}</p>
            </div>
          }
          <div className="summary bold">
            <p>Paid</p>
            <p><span className="rupee">₹</span>{order.final_total}</p>
          </div>
          <div className="divider"></div>
          <div className="print_footer">
            <p className="header">Thank You. Visit Again.</p>
            <div className="divider"></div>
            <div className="disclaimer">
              <p>THIS IS COMPUTER GENERATED INVOICE</p>
              <p>SIGN & STAMP NOT REQUIRED</p>
              <p>NO RETURN * NO EXCHANGE * NO REFUND</p>
            </div>
            <div className="divider"></div>
            <p className="end">Receipts by AirMenus.in</p>
          </div>
        </div>
      }
    </div>
  );
});
