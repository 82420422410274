import api from './Api';

export const getCustomerOrder = async (order_id) => {
   return api.get(`${process.env.REACT_APP_BASE_URL}order/admin/${order_id}/`)
        .then(response => response.data);
};

export const updateCustomerOrderAddress = async (
      { id, formatted_address, latitude, longitude, house_no, landmark }
   ) => {
      return api.put(
         `${process.env.REACT_APP_BASE_URL}general/address/${id}/`,
         { formatted_address, latitude, longitude, house_no, landmark }
      ).then(response => response.data);
};

export const updateUserDetails = async (user) => {
   return api.put(
      `${process.env.REACT_APP_BASE_URL}auth/admin/user/${user.id}/`,
      user
   ).then(response => response.data);
};
