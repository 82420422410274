import api from './Api';
import { makeValidJson } from '../helpers/Utils';

export const getBrandDetails = async (short_name) => {
   return api.get(
      `${process.env.REACT_APP_BASE_URL}restaurant/admin/${short_name}/`,
   ).then(response => response.data);
};

export const updateBrandDetails = async (brand) => {
   return api.put(
      `${process.env.REACT_APP_BASE_URL}restaurant/update/${brand.id}/`,
      brand
   ).then(response => response.data);
};

export const updateOutletDetails = async (outlet) => {
   if (!outlet) {
      return Promise.resolve();
   }
   return api.put(
      `${process.env.REACT_APP_BASE_URL}restaurant/admin/outlet/${outlet.id}/`,
      outlet
   ).then(response => response.data);
};

export const updateLoginDetails = async (user) => {
   if (!user) {
      return Promise.resolve();
   }
   return api.put(
      `${process.env.REACT_APP_BASE_URL}auth/admin/user/${user.id}/`,
      user
   ).then(response => response.data);
};

export const resetUserLoginPassword = async (user) => {
   return api.post(
      `${process.env.REACT_APP_BASE_URL}auth/admin/partner/reset/password/`,
      user
   ).then(response => response.data);
};

export const updateOutletOrderConfig = async (order_config) => {
   if (!order_config) {
      return Promise.resolve();
   }
   return api.put(
      `${process.env.REACT_APP_BASE_URL}restaurant/outlet/order-config/${order_config.id}/`,
      order_config
   ).then(response => response.data);
};

export const updateOutletMenuConfig = async (menu_config) => {
   if (!menu_config) {
      return Promise.resolve();
   }
   const data = {
      ...menu_config,
      display_names: makeValidJson(menu_config.display_names),
   }
   return api.put(
      `${process.env.REACT_APP_BASE_URL}restaurant/outlet/menu-config/${menu_config.id}/`,
      data
   ).then(response => response.data);
};
