import axios from "axios";

export const login = async ({ email, password }) => {
  return axios.post(
      `${process.env.REACT_APP_BASE_URL}auth/merchant/login/`,
      { partner_id: email, password}
    )
    .then(response => {
      saveAuthDetails(response.data);
      return response;
    });
};

const saveAuthDetails = ({ access, refresh }) => {
  const authDetails = { access, refresh };
  localStorage.setItem('authDetails', JSON.stringify(authDetails));
}
