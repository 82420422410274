export const copyText = (text) => {
  const textField = document.createElement('textarea')
  textField.innerText = text || '';
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove();
}

export const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getOrderIdsFromRecords = (records) => {
  return records.reduce((result, record) => {
    if (!result.some(el => el.value === `${record.placed_order}`)) {
      result.push({
        text: `${record.placed_order}`,
        value: `${record.placed_order}`
      });
    }
    return result;
  }, []);
}

export const getStatusFromRecords = (records) => {
  return records.reduce((result, record) => {
    if (!result.some(el => el.value === record.status)) {
      result.push({
        text: record.status,
        value: record.status,
      });
    }
    return result;
  }, []);
}

export const getPhoneNos = (outlet) => {
  return outlet.sms_list.split(',').map(no => no.slice(2)).join(' / ');
};

export const getFormattedAddress = ({ house_no, formatted_address, landmark }) => {
  const fAddress = [house_no, formatted_address, landmark];
  return fAddress.filter(el => el).join(', ');
};

export const getSubTax = (tax) => (+tax) / 2;

export const getItemVariationNames = (variations) => {
  const selectedFVars = [...variations.filter(variation => variation.level === 1)];
  const selectedSVars = [...variations.filter(variation => variation.level === 2)];
  const selectedTVars = [...variations.filter(variation => variation.level === 3)];
  if (selectedTVars.length > 0) { return selectedTVars; }
  if (selectedSVars.length > 0) { return selectedSVars; }
  if (selectedFVars.length > 0) { return selectedFVars; }
  return variations;
}

export const transformOrderDetails = ({ details, outlet }) => {
  const hasSubVariations = checkIfGroupSubvariations(outlet);
  return details.map(res => {
      const l1VariationTotal = getL1VariationTotal(res);
      const l2VariationTotal = res.variations
          .filter(variation => variation.level === 2)
          .reduce((total, variation) =>
              total += variation.variation_price + variation.mark_up, 0);
      const l3VariationTotal = res.variations
          .filter(variation => variation.level === 3)
          .reduce((total, variation) =>
              total += variation.variation_price + variation.mark_up, 0);
      const addonsTotal = res.addons.reduce((total, addon) =>
          total += addon.price, 0);
      if (hasSubVariations && l3VariationTotal !== 0) {
          const netPrice = (l1VariationTotal + l3VariationTotal + addonsTotal) * res.quantity;
          return Object.assign(res, { item_price: netPrice.toFixed(2) });
      }
      const netPrice = (l2VariationTotal === 0)
        ? ((+res.item_price) + addonsTotal + l1VariationTotal) * res.quantity
        : (l2VariationTotal + addonsTotal) * res.quantity;
      const result = Object.assign(
          res,
          {
            item_price: netPrice.toFixed(2),
            item_slot: (res.delivery_slot || res.take_away_slot || res.inroomdining_slot)
              ? ''
              : res.item_slot,
          }
      );
      return result;
  });
}

const checkIfGroupSubvariations = (outlet) => {
  return outlet?.group_subvariations;
}

const getL1VariationTotal = (details) => {
  if (details?.item_buy_config) {
      return details.variations
        .filter(variation => variation.level === 1)
        .sort((a, b) => +b.variation_price - +a.variation_price)
        .slice(0, details.item_buy_config.x)
        .reduce((total, variation) =>
          total += (variation.variation_price * (variation.quantity || 1))
            + variation.mark_up, 0);
  }
  return details.variations
    .filter(variation => variation.level === 1)
    .reduce((total, variation) =>
      total += (variation.variation_price * (variation.quantity || 1))
        + variation.mark_up, 0);
}

export const date_to_ist = (date) => {
  const copy = new Date(date);
  return new Date(new Date(copy.setHours(date.getHours() + 5)).setMinutes(date.getMinutes() + 30));
}

export const makeValidJson = (value) => {
  if (typeof value === 'object') {
    return value;
  }
  if (typeof value !== 'string') {
    return {};
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
}
