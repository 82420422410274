import api from './Api';

let dunzoAccessToken;

const canCancelDunzo = (state) => {
   switch(state) {
      case 'created': ;
      case 'queued': ;
      case 'runner_accepted': ;
      case 'reached_for_pickup': ;
      case 'pickup_complete':
          return true;
      case 'started_for_delivery': ;
      case 'reached_for_delivery': ;
      case 'delivered': ;
      case 'cancelled': ;
      case 'runner_cancelled':
          return false;
      default:
         return false;
   }
}

export const getDeliveries = async () => {
   return api.get(`${process.env.REACT_APP_BASE_URL}vendor/delivery/tasks/`)
        .then(
           response => response.data.map(
              record => Object.assign(
                 record,
                 {
                    key: record.id,
                    canCancelDunzo: canCancelDunzo(record.status),
                 }
              )
           )
        );
};

export const updateDeliveryRecord = async ({ id, status, tracking_link, is_deleted, placed_order }) => {
   return api.put(
       `${process.env.REACT_APP_BASE_URL}vendor/delivery/record/${id}/`,
       { status, tracking_link, is_deleted, placed_order }
   )
    .then(response => response.data);
};

const getDunzoToken = async () => {
   if (dunzoAccessToken) { return Promise.resolve(dunzoAccessToken);}
   return api.get(`${process.env.REACT_APP_BASE_URL}vendor/delivery/dunzo/access-token/`)
        .then(response => {
           dunzoAccessToken = response.data;
           return response.data;
        });
};

export const createScheduledDunzoTask = async (id) => {
   const dunzoAccessToken = await getDunzoToken();
   const postData = {
      dunzo_access_token: dunzoAccessToken.dunzo_access_token,
      order_id: id,
      task_minutes_ahead: 32,
   };
   return api.post(
       `${process.env.REACT_APP_BASE_URL}vendor/delivery/dunzo/task/schedule/`,
      postData
   )
    .then(response => response.data);
};

export const cancelDeliveryTask = async ({ task_id, reason, partner }) => {
   const postData = {
      task_id,
      reason,
   };
   return api.post(
       `${process.env.REACT_APP_BASE_URL}vendor/delivery/partner/task/cancel/`,
      postData,
      {
         params: {
            partner
         }
      }
   )
    .then(response => response.data);
};

export const createDeliveryTask = async (order_id, partner) => {
   const postData = {
      order_id,
   };
   return api.post(
       `${process.env.REACT_APP_BASE_URL}vendor/delivery/partner/task/create/`,
      postData,
      {
         params: {
            partner
         }
      }
   )
    .then(response => response.data);
};

export const correctDeliveryRecord = async ({ id, partner }) => {
   return api.post(
       `${process.env.REACT_APP_BASE_URL}vendor/delivery/partner/task/${id}/correct/`,
      '',
      {
         params: {
            partner
         }
      }
   )
    .then(response => response.data);
};

export const getOrderDeliveries = async (order_id) => {
   return api.get(`${process.env.REACT_APP_BASE_URL}vendor/delivery/admin/tasks/${order_id}/`)
        .then(
           response => response.data.map(
              record => Object.assign(
                 record,
                 {
                    key: record.id,
                    canCancelDunzo: canCancelDunzo(record.status),
                 }
              )
           )
        );
};
