import React, { useState } from 'react';
import {
  Input,
  Form,
  Button,
  Space,
  notification,
  Switch,
  Row,
  Col,
  Select,
  InputNumber,
} from 'antd';
import {
  applyOutletMenuActions,
} from '../services/Menu';

import "../assets/styles/delivery.css";

const Outlet_Menu = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [outletId, setOutletId] = useState();
  const [state, setState] = useState({
    actions: [],
    tax: 0,
    vat: 0,
    is_dinein: false,
    is_takeaway: false,
    is_delivery: false,
    is_inroom: false,
    packaging_charges: 0,
  });

  const menu_actions = [
    {
      label: 'Set TAX',
      value: 'set_tax',
    },
    {
      label: 'Set VAT',
      value: 'set_vat',
    },
    {
      label: 'Set Is Dine-In',
      value: 'set_is_dinein',
    },
    {
      label: 'Set Is Take-Away',
      value: 'set_is_takeaway',
    },
    {
      label: 'Set Is Delivery',
      value: 'set_is_delivery',
    },
    {
      label: 'Set Is In-Room',
      value: 'set_is_inroom',
    },
    {
      label: 'Set Packaging Charge',
      value: 'set_packaging_charges',
    },
    {
      label: 'Make item names in proper case',
      value: 'make_item_names_proper_case',
    },
    {
      label: 'Make item descriptions in proper case',
      value: 'make_item_description_proper_case',
    },
    {
      label: 'Make item variation names in proper case',
      value: 'make_item_variation_names_proper_case',
    },
    {
      label: 'Make item variation descriptions in proper case',
      value: 'make_item_variation_descriptions_proper_case',
    },
  ];

  const handleActionChanges = (values) => {
    setState({
      ...state,
      actions: values
    });
  };

  const handleChange = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    switch(field) {
      default:
        setState({
          ...state,
          [field]: (typeof e === 'object') ? ev.target.value : e,
        });
    }
  };

  const applyActions = async () => {
    setIsLoading(true);
    await applyOutletMenuActions({
      ...state,
      outlet_ids: [outletId]
    })
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <Space size="large" direction="vertical">
        <Form form={form} name="outlet_id" layout="inline">
          <Form.Item
            name="outlet_id"
            rules={[{ required: true, message: 'Please input outlet id!' }]}
          >
            <Input
              type="number"
              placeholder="Outlet Id"
              onChange={(e) => setOutletId(e.target.value)}
            />
          </Form.Item>
        </Form>
        {
          outletId &&
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%', }}
            placeholder="Please menu action"
            onChange={handleActionChanges}
            options={menu_actions}
          />
        }
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                {
                  state.actions.includes('set_tax') &&
                  <Col>
                    <Form.Item
                      label="Set TAX"
                      rules={[{ required: true, message: 'Please input tax value!' }]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        defaultValue={state.tax}
                        onChange={(value) => {
                          setState({
                            ...state,
                            tax: value
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_vat') &&
                  <Col>
                    <Form.Item
                      label="Set VAT"
                      rules={[{ required: true, message: 'Please input vat value!' }]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        defaultValue={state.vat}
                        onChange={(value) => {
                          setState({
                            ...state,
                            vat: value
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_is_dinein') &&
                  <Col>
                    <Form.Item
                      label="Is Dine-In?"
                      rules={[{ required: true, message: 'Please input outlet items is dinein status!' }]}
                    >
                      <Switch
                        checked={state.is_dinein}
                        name="is_dinein"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_is_takeaway') &&
                  <Col>
                    <Form.Item
                      label="Is Take-Away?"
                      rules={[{ required: true, message: 'Please input outlet items is takeaway status!' }]}
                    >
                      <Switch
                        checked={state.is_takeaway}
                        name="is_takeaway"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_is_delivery') &&
                  <Col>
                    <Form.Item
                      label="Is Delivery?"
                      rules={[{ required: true, message: 'Please input outlet items is delivery status!' }]}
                    >
                      <Switch
                        checked={state.is_delivery}
                        name="is_delivery"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_is_inroom') &&
                  <Col>
                    <Form.Item
                      label="Is In-Room?"
                      rules={[{ required: true, message: 'Please input outlet items is inroom status!' }]}
                    >
                      <Switch
                        checked={state.is_inroom}
                        name="is_inroom"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                }
                {
                  state.actions.includes('set_packaging_charges') &&
                  <Col>
                    <Form.Item
                      label="Set Packaging Charge"
                      rules={[{ required: true, message: 'Please input packaging charge value!' }]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        defaultValue={state.packaging_charges}
                        onChange={(value) => {
                          setState({
                            ...state,
                            packaging_charges: value
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                }
              </Row>
            </Form>
          </Col>
        </Row>
        {
          outletId &&
          state.actions.length > 0 &&
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                onClick={applyActions}
                loading={isLoading}
              >
                APPLY ACTIONS
              </Button>
            )}
          </Form.Item>
        }
      </Space>
    </>
  );
}

export default Outlet_Menu;
