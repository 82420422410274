import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  LinkOutlined,
  CopyOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Table,
  Input,
  Switch,
  Popconfirm,
  Form,
  Typography,
  Select,
  Tooltip,
  Button,
  Space,
  Row,
  Col,
} from 'antd';
import {
  getDeliveries,
  updateDeliveryRecord,
  createScheduledDunzoTask,
  cancelDeliveryTask,
  createDeliveryTask,
  correctDeliveryRecord,
  getOrderDeliveries,
} from '../services/Vendor';
import {
  copyText,
  capitalizeFirstLetter,
  getOrderIdsFromRecords,
  getStatusFromRecords,
} from '../helpers/Utils';
import "../assets/styles/delivery.css";
const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const [isDeleted, setIsDeleted] = useState(record?.is_deleted || false);
  const inputNode = (inputType) => {
    const checkChange = (value) => {
      record.is_deleted = value;
      setIsDeleted(value);
    }
    switch(inputType) {
      case "text": return <Input />;
      case "checkbox":
        return <Switch
            checked={isDeleted}
            onClick={checkChange}
          />;
      case "select":
        return <Select initialvalue={record?.status || 'created'}>
            <Option value="created">Created</Option>
            <Option value="queued">Queued</Option>
            <Option value="runner_accepted">Runner Accepted</Option>
            <Option value="reached_for_pickup">Reached for Pick-Up</Option>
            <Option value="started_for_delivery">Started for Delivery</Option>
            <Option value="delivered">Delivered</Option>
          </Select>;
      default: return <Input />;
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: (
                (record?.delivery_partner === 'dunzo' && record?.status === "started_for_delivery") ||
                (record?.delivery_partner === 'porter' && record?.status === "started_for_delivery") ||
                (record?.delivery_partner === 'genie' && record?.status === "runner_accepted")
              ),
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode(inputType)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Delivery = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getDeliveries()
      .then(data => {
        setData(data);
        setOrderIds(getOrderIdsFromRecords(data));
        setStatuses(getStatusFromRecords(data));
      });
  }

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      status: '',
      tracking_link: '',
      is_deleted: false,
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        const updatedData = { ...item, ...row };
        updateDeliveryRecord(updatedData)
          .then(_ => {
            newData.splice(index, 1, updatedData);
            setData(newData);
            setEditingKey('');
          });
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const scheduleDunzo = async (id, prev_task_id, prev_partner) => {
    try {
      setIsLoading(true);
      await createScheduledDunzoTask(id);
      if (prev_partner === 'genie') {
        await cancelDeliveryTask({
          task_id: prev_task_id,
          reason: 'Cancelled by Admin',
          partner: 'genie'
        });
      }
      const newData = await getDeliveries();
      setData(newData);
    } catch (errInfo) {
      console.log('Scheduling Task Failed:', errInfo);
    }
    setIsLoading(false);
  };

  const createTask = async (id, partner, prev_task_id, prev_partner) => {
    try {
      setIsLoading(true);
      await createDeliveryTask(id, partner);
      if (prev_partner === 'genie') {
        await cancelDeliveryTask({
          task_id: prev_task_id,
          reason: 'Cancelled by Admin',
          partner: 'genie'
        });
      }
      const newData = await getDeliveries();
      setData(newData);
    } catch (errInfo) {
      console.log('Creating Task Failed:', errInfo);
    }
    setIsLoading(false);
  };

  const cancelTask = async (task_id, reason, partner) => {
    try {
      setIsLoading(true);
      await cancelDeliveryTask({ task_id, reason, partner });
      const newData = await getDeliveries();
      setData(newData);
    } catch (errInfo) {
      console.log('Cancelling Task Failed:', errInfo);
    }
    setIsLoading(false);
  };

  const correctRecordTask = async (id, partner) => {
    try {
      setIsLoading(true);
      await correctDeliveryRecord({ id, partner });
      const newData = await getDeliveries();
      setData(newData);
    } catch (errInfo) {
      console.log('Correcting Task Failed:', errInfo);
    }
    setIsLoading(false);
  };

  const getOrderDeliveryRecords = async (orderId) => {
    if (!orderId) {
      getData();
      return
    }
    try {
      setIsLoading(true);
      const deliveries = await getOrderDeliveries(orderId);
      setData(deliveries);
      setOrderIds(getOrderIdsFromRecords(deliveries));
      setStatuses(getStatusFromRecords(deliveries));
    } catch (err) {
      console.log(`Getting deliveries failed for ${orderId}:`, err);
    }
    setIsLoading(false);
  }

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'placed_order',
      render: (order_id, record) =>
        <Space size="large">
          <span>{order_id}</span>
          {
            (record.delivery_partner === 'dunzo') &&
            <img
              className="delivery-partner-img"
              src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/mobh5yfx7aojir1vhnjf"
              alt='Dunzo Logo'
            />
          }
          {
            (record.delivery_partner === 'genie') &&
            <img
              className="delivery-partner-img"
              src="https://cdn.worldvectorlogo.com/logos/swiggy-1.svg"
              alt='Genie Logo'
            />
          }
          {
            (record.delivery_partner === 'porter') &&
            <img
              className="delivery-partner-img"
              src="https://media.glassdoor.com/sqll/1480906/the-porter-squarelogo-1519900873687.png"
              alt='Dunzo Logo'
            />
          }
        </Space>,
      filters: [...orderIds],
      filterSearch: true,
      onFilter: (value, record) => `${record.placed_order}`.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      editable: true,
      filters: [...statuses],
      filterSearch: true,
      onFilter: (value, record) => record.status.startsWith(value),
    },
    {
      title: 'Task Id',
      dataIndex: 'task_id',
      render: (_, record) =>
      (record.delivery_partner !== 'genie')
      ? <Space size="large">
        {
          record.task_id &&
          <Tooltip title="copy task id">
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() => copyText(record.task_id)}
            >
              {record.task_id}
              <CopyOutlined style={{ 'marginLeft': '0.5rem'}}
            />
            </a>
          </Tooltip>
        }
        </Space>
      : <span></span>
    },
    {
      title: 'Tracking Link',
      dataIndex: 'tracking_link',
      width: '40%',
      editable: true,
      render: (link) =>
        <a
          className='tracking-link'
          target="_blank"
          rel="noreferrer"
          href={link}>
          {link}
          {(link) ? <LinkOutlined style={{ 'marginLeft': '0.5rem'}}/>: ''}
        </a>
    },
    {
      title: 'Is Deleted?',
      dataIndex: 'is_deleted',
      editable: true,
      render: (is_deleted, record) =>
          <Space direction="vertical">
            <Switch checked={is_deleted} />
            <span>{record.cancellation_reason}</span>
          </Space>
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link" block>
                Cancel
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    const calcInputType = (type) => {
      switch(type) {
        case 'is_deleted': return 'checkbox';
        case 'status': return 'select';
        default: return 'text';
      }
    };

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: calcInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Space size="large" direction="vertical" style={{ width: '100%' }}>
      <Input.Search
        placeholder="Search deliveries for an order"
        onSearch={getOrderDeliveryRecords}
        allowClear
        enterButton="Search"
        loading={isLoading}
        className="search_input"
      />
      <Form form={form} component={false}>
        <div className="table-responsive">
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            expandable={{
              expandedRowRender: record => {
                const customerAddressUrl = `https://www.google.com/maps/search/?api=1&query=${record.user.address.latitude},${record.user.address.longitude}`;
                const outletAddressUrl = `https://www.google.com/maps/search/?api=1&query=${record.outlet.address.latitude},${record.outlet.address.longitude}`;
                return <Row>
                <Col span={12}>
                  <Space direction="vertical" size="large">
                      <Space size="large">
                        <b>Booking Details</b>
                      </Space>
                      <Space size="large">
                        <span>Created At:</span>
                        <span>{format(new Date(record.created_at), 'dd/MM/yy, h:mm:ss a')}</span>
                      </Space>
                      <Space size="large">
                        <span>Partner:</span>
                        <span>{capitalizeFirstLetter(record.delivery_partner)}</span>
                      </Space>
                      {
                        record.rider_name &&
                        <Space size="large">
                          <span>Rider Name:</span>
                          <span>{record.rider_name}</span>
                          <Tooltip title="copy rider name">
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => copyText(record.rider_name)}
                            />
                          </Tooltip>
                        </Space>
                      }
                      {
                        record.rider_phonenumber &&
                        <Space size="large">
                          <span>Rider Phone Number:</span>
                          <a href="tel:{record.rider_phonenumber}">
                            {record.rider_phonenumber}
                          </a>
                          <Tooltip title="copy rider phone number">
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => copyText(record.rider_phonenumber)}
                            />
                          </Tooltip>
                        </Space>
                      }
                      <Space size="large">
                        <span>Outlet Name:</span>
                        <span>{record.outlet.name}</span>
                      </Space>
                      <Space size="large">
                        <span>Phone Number:</span>
                        <a href="tel:{record.user.phone_number}">
                          {record.outlet.phone_number}
                        </a>
                        <Tooltip title="copy outlet phone number">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => copyText(record.outlet.phone_number)}
                          />
                        </Tooltip>
                      </Space>
                      <Space size="large">
                        <span>Brand:</span>
                        <span>{record.outlet.brand_id}</span>
                        <Tooltip title="copy brand id">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => copyText(record.outlet.brand_id)}
                          />
                        </Tooltip>
                      </Space>
                      <Space size="large">
                        <a href={outletAddressUrl} target="_blank">
                          See outlet address on map
                          <LinkOutlined style={{ 'marginLeft': '0.5rem'}} />
                        </a>
                      </Space>
                      <Space size="large">
                        <Space size="large">
                          <Button
                            type="primary"
                            className='dunzo-btn'
                            icon={<ClockCircleOutlined />}
                            loading={isLoading}
                            onClick={() => scheduleDunzo(
                              record.placed_order,
                              record.task_id,
                              record.delivery_partner,
                            )}
                          >
                            Re-Schedule As Dunzo
                          </Button>
                        </Space>
                        <Space size="large">
                          <Button
                            type="primary"
                            className='porter-btn'
                            icon={<ClockCircleOutlined />}
                            loading={isLoading}
                            onClick={
                              () => createTask(
                                record.placed_order,
                                'porter',
                                record.task_id,
                                record.delivery_partner,
                              )
                            }
                          >
                            Book Porter
                          </Button>
                        </Space>
                        {
                          !record.task_id &&
                          <Space size="large">
                            <Button
                              type="primary"
                              className='correct-task-btn'
                              icon={<WarningOutlined />}
                              loading={isLoading}
                              onClick={() => correctRecordTask(
                                record.id,
                                record.delivery_partner,
                              )}
                            >
                              Correct Task Id
                            </Button>
                          </Space>
                        }
                        {
                          (record.delivery_partner !== 'genie') &&
                          (!record.is_deleted) &&
                          (record.canCancelDunzo) &&
                          <Space size="large">
                            <Popconfirm
                              title="Sure to cancel?"
                              onConfirm={
                                () => cancelTask(
                                  record.task_id,
                                  'Cancelled by Admin',
                                  record.delivery_partner,
                                )
                              }
                            >
                              <Button
                                type="primary"
                                icon={<CloseCircleOutlined />}
                                loading={isLoading}
                                danger
                              >
                                Cancel Task
                              </Button>
                            </Popconfirm>
                          </Space>
                        }
                      </Space>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space direction="vertical" size="large">
                      <Space size="large">
                        <b>Customer Details</b>
                      </Space>
                      <Space size="large">
                        <span>Name:</span>
                        <span>{record.user.name}</span>
                      </Space>
                      <Space size="large">
                        <span>Phone Number:</span>
                        <a href="tel:{record.user.phone_number}">
                          {record.user.phone_number}
                        </a>
                        <Tooltip title="copy customer phone number">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => copyText(record.user.phone_number)}
                          />
                        </Tooltip>
                      </Space>
                      <Space size="large">
                        <span>Address:</span>
                        <span>{record.user.address.formatted_address}</span>
                        <Tooltip title="copy customer address">
                          <Button
                            icon={<CopyOutlined />}
                            onClick={() => copyText(record.user.address.formatted_address)}
                          />
                        </Tooltip>
                      </Space>
                      {
                        record.user.address.house_no &&
                        <Space size="large">
                          <span>House No:</span>
                          <span>{record.user.address.house_no}</span>
                          <Tooltip title="copy customer house no">
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => copyText(record.user.address.house_no)}
                            />
                          </Tooltip>
                        </Space>
                      }
                      {
                        record.user.address.landmark &&
                        <Space size="large">
                          <span>Landmark:</span>
                          <span>{record.user.address.landmark}</span>
                          <Tooltip title="copy customer landmark">
                            <Button
                              icon={<CopyOutlined />}
                              onClick={() => copyText(record.user.address.landmark)}
                            />
                          </Tooltip>
                        </Space>
                      }
                      {
                        record.user.by_road_distance &&
                        <Space size="large">
                          <span>Distance from Outlet:</span>
                          <span>{record.user.by_road_distance} KM</span>
                        </Space>
                      }
                      <Space size="large">
                        <a href={customerAddressUrl} target="_blank">
                          See customer address on map
                          <LinkOutlined style={{ 'marginLeft': '0.5rem'}} />
                        </a>
                      </Space>
                  </Space>
                </Col>
              </Row>
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </div>
      </Form>
    </Space>
  );
}

export default Delivery;
