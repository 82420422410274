import React, { useEffect, useState } from 'react';
import {
  Input,
  Form,
  Button,
  Space,
  Select,
  Row,
  Col,
  Switch,
} from 'antd';
import { format } from 'date-fns';
import {
  getBrandDetails,
  updateBrandDetails,
  updateOutletDetails,
  updateLoginDetails,
  resetUserLoginPassword,
  updateOutletOrderConfig,
  updateOutletMenuConfig,
} from '../services/Brand';
import { date_to_ist } from '../helpers/Utils';
import JSONInput from 'react-json-editor-ajrm';

import "../assets/styles/delivery.css";
import TextArea from 'antd/lib/input/TextArea';

const Brand_Data = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState(undefined);
  const [outlets, setOutlets] = useState(undefined);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedOutlet, setSelectedOutlet] = useState(undefined);
  const [selectedOrderConfig, setSelectedOrderConfig] = useState(undefined);
  const [selectedMenuConfig, setSelectedMenuConfig] = useState(undefined);
  const [brandShortName, setBrandShortName] = useState(0);
  const [brandState, setBrandState] = useState({});
  const [brandLoginData, setBrandLoginData] = useState(undefined);
  const [outletLoginData, setOutletLoginData] = useState(undefined);

  useEffect(() => {
    if (selectedBrand) {
      setBrandState({
        ...brandState,
        ...selectedBrand,
        short_name: selectedBrand.short_name,
        id: selectedBrand.id,
      });
      setBrandLoginData(selectedBrand.user);
      setOutlets(selectedBrand.outlets || []);
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (brands && brands.length === 1) {
      selectBrand(brands[0].id);
    }
  }, [brands]);

  const getBrandData = () => {
    setIsLoading(true);
    setBrands(undefined);
    setOutlets(undefined);
    setSelectedBrand(undefined);
    setSelectedOutlet(undefined);
    getBrandDetails(brandShortName)
      .then(data => {
        setBrands(data);
        setIsLoading(false);
      })
      .catch(_ => setIsLoading(false));
  };

  const updateAllData = async () => {
    setIsLoading(true);
    await updateOutletMenuConfig(selectedMenuConfig);
    await updateOutletOrderConfig(selectedOrderConfig);
    await updateOutletDetails(selectedOutlet);
    await updateLoginDetails(brandLoginData);
    await updateLoginDetails(outletLoginData);
    await updateBrandDetails(brandState);
    setIsLoading(false);
  };

  const resetBrandPassword = async () => {
    setIsLoading(true);
    await resetUserLoginPassword({
      new_password: 'testing',
      user_id: brandLoginData.id,
    });
    setIsLoading(false);
  };

  const resetOutletPassword = async () => {
    setIsLoading(true);
    await resetUserLoginPassword({
      new_password: 'testing',
      user_id: outletLoginData.id,
    });
    setIsLoading(false);
  };

  const handleBrandChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    switch(field) {
      case 'use_appearance_module':
        setBrandState({
          ...brandState,
          extra_config: {
            ...brandState.extra_config,
            use_appearance_module: e,
          }
        });
        break;
      default:
        setBrandState({
          ...brandState,
          [field]: (typeof e === 'object') ? ev.target.value : e,
        });
    }
  };

  const handleOutletChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    switch(field) {
      case 'repeat_order_notifications':
        setSelectedOutlet({
          ...selectedOutlet,
          extra_config: {
            ...selectedOutlet.extra_config,
            repeat_order_notifications: e,
          }
        });
        break;
      case 'petpooja_restaurant_id':
        setSelectedOutlet({
          ...selectedOutlet,
          extra_config: {
            ...selectedOutlet.extra_config,
            petpooja_restaurant_id: ev.target.value,
          }
        });
        break;
      case 'posist_customer_key':
        setSelectedOutlet({
          ...selectedOutlet,
          extra_config: {
            ...selectedOutlet.extra_config,
            posist_customer_key: ev.target.value,
          }
        });
        break;
      case 'delivery_partner_preference':
        setSelectedOutlet({
          ...selectedOutlet,
          extra_config: {
            ...selectedOutlet.extra_config,
            delivery_partner_preference: ev.target.value,
          }
        });
        break;
      default:
        setSelectedOutlet({
          ...selectedOutlet,
          [field]: (typeof e === 'object') ? ev.target.value : e,
        });
    }
  };

  const handleBrandLoginChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    setBrandLoginData({
      ...brandLoginData,
      [field]: (typeof e === 'object') ? ev.target.value : e,
    });
  };

  const handleOutletLoginChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    setOutletLoginData({
      ...outletLoginData,
      [field]: (typeof e === 'object') ? ev.target.value : e,
    });
  };

  const handleOrderConfigChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    switch(field) {
      case 'hide_table_no_on_checkout':
        setSelectedOrderConfig({
          ...selectedOrderConfig,
          extra_config: {
            ...selectedOrderConfig.extra_config,
            hide_table_no_on_checkout: e,
          }
        });
        break;
      default:
        setSelectedOrderConfig({
          ...selectedOrderConfig,
          [field]: (typeof e === 'object') ? ev.target.value : e,
        });
    }
  };

  const handleMenuConfigChanges = (e, switch_event) => {
    const field = switch_event ? switch_event.target.name : e.target.name;
    const ev = switch_event || e;
    switch(field) {
      case 'hide_footer':
        setSelectedMenuConfig({
          ...selectedMenuConfig,
          extra_config: {
            ...selectedMenuConfig.extra_config,
            hide_footer: e,
          }
        });
        break;
      default:
        setSelectedMenuConfig({
          ...selectedMenuConfig,
          [field]: (typeof e === 'object') ? ev.target.value : e,
        });
    }
  };

  const selectBrand = (value) => {
    const brand = brands.find(brand => brand.id === value);
    setSelectedBrand(brand);
  };

  const selectOutlet = (value) => {
    const outlet = outlets.find(outlet => outlet.id === value);
    setSelectedOutlet(outlet);
    setSelectedOrderConfig(outlet.order_config);
    setSelectedMenuConfig(outlet.menu_config);
    setOutletLoginData(outlet.user);
  };

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Form form={form} name="brand_data" layout="inline">
          <Form.Item
            name="brand_short_name"
            rules={[{ required: true, message: 'Please input brand short name!' }]}
          >
            <Input
              type="text"
              placeholder="Brand Short Name"
              onChange={(e) => setBrandShortName(e.target.value)}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                disabled={
                  !brandShortName
                }
                onClick={getBrandData}
                loading={isLoading}
              >
                SEARCH
              </Button>
            )}
          </Form.Item>
        </Form>
        {
          brands && brands.length > 1 &&
          <Form.Item>
            <Select
              placeholder="Select Brand"
              style={{ width: 400 }}
              onChange={selectBrand}
              options={
                brands.map(brand => Object.assign(brand, {
                    value: brand.id,
                    label: brand.name
                }))
              }
            />
          </Form.Item>
        }
        {
          selectedBrand && brandShortName &&
          <>
            <Row>
              <Col span={24}>
                <h3>Restaurant Data</h3>
                <Form form={form} layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <Form.Item
                        label="Short Name"
                        rules={[{ required: true, message: 'Please input restaurant short name!' }]}
                      >
                        <Input
                          type='text'
                          value={brandState.short_name}
                          name="short_name"
                          placeholder="Short Name"
                          onChange={handleBrandChanges}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Id"
                        rules={[{ required: true, message: 'Please input restaurant id!' }]}
                      >
                        <Input
                          type='text'
                          value={brandState.id}
                          name="id"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Enable Appearance Module?"
                        rules={[{ required: true, message: 'Please input brand appearance module!' }]}
                      >
                        <Switch
                          checked={brandState?.extra_config?.use_appearance_module || false}
                          name="use_appearance_module"
                          onChange={handleBrandChanges}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            {
              brandLoginData &&
              <Row>
                <Col span={24}>
                  <h3>Master Login Data</h3>
                  <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Email"
                          rules={[{ required: true, message: 'Please input brand login email!' }]}
                        >
                          <Input
                            type='text'
                            value={brandLoginData.email}
                            name="email"
                            placeholder="Email"
                            onChange={handleBrandLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Partner Id"
                          rules={[{ required: true, message: 'Please input brand login partner id!' }]}
                        >
                          <Input
                            type='text'
                            value={brandLoginData.phone_number}
                            name="phone_number"
                            placeholder="Partner Id"
                            onChange={handleBrandLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Is Manager?"
                          rules={[{ required: true, message: 'Please input brand is manager status!' }]}
                        >
                          <Switch
                            checked={brandLoginData.is_manager}
                            name="is_manager"
                            onChange={handleBrandLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={resetBrandPassword}
                              loading={isLoading}
                            >
                              RESET LOGIN PASSWORD
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
            {
              outlets &&
              <Form.Item>
                <Select
                  placeholder="Select Outlet"
                  style={{ width: 400 }}
                  onChange={selectOutlet}
                  options={
                    outlets.map(outlet => Object.assign(outlet, {
                        value: outlet.id,
                        label: outlet.name
                    }))
                  }
                />
              </Form.Item>
            }
            {
              selectedOutlet &&
              <Row>
                <Col span={24}>
                  <h3>Outlet Data</h3>
                  <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Id"
                          rules={[{ required: true, message: 'Please input outlet id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.id}
                            name="id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="User Id"
                          rules={[{ required: true, message: 'Please input outlet user id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.user_id}
                            name="User Id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Timings Id"
                          rules={[{ required: true, message: 'Please input outlet timings id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.timings_id}
                            name="Timings Id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Address Id"
                          rules={[{ required: true, message: 'Please input outlet address id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.address_id}
                            name="Address Id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Order Config Id"
                          rules={[{ required: true, message: 'Please input outlet order config id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.order_config.id}
                            name="Order Config Id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Menu Config Id"
                          rules={[{ required: true, message: 'Please input outlet menu config id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.menu_config.id}
                            name="Menu Config Id"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Last Updated At"
                          rules={[{ required: true, message: 'Please input outlet last updated at!' }]}
                        >
                          <Input
                            type='text'
                            value={format(date_to_ist(new Date(selectedOutlet.updated_at)), 'dd-mm-yyy HH:mm:ss')}
                            name="Last Updated At"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Short Name"
                          rules={[{ required: true, message: 'Please input outlet short name!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.short_name}
                            name="short_name"
                            placeholder="Short Name"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Name"
                          rules={[{ required: true, message: 'Please input outlet name!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.name}
                            name="name"
                            placeholder="Name"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Account Id"
                          rules={[{ required: true, message: 'Please input outlet account id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.account_id}
                            name="account_id"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Phone Number"
                          rules={[{ required: true, message: 'Please input outlet phone number!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.phone_number}
                            name="phone_number"
                            placeholder="Phone Number"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Gateway Commision"
                          rules={[{ required: true, message: 'Please input outlet gateway commision!' }]}
                        >
                          <Input
                            type='number'
                            value={selectedOutlet.gateway_commision}
                            name="gateway_commision"
                            placeholder="Gateway Commision"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Reversal Amount"
                          rules={[{ required: true, message: 'Please input outlet reversal amount!' }]}
                        >
                          <Input
                            type='number'
                            value={selectedOutlet.reversal_amount}
                            name="reversal_amount"
                            placeholder="Reversal Amount"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Online Convenience Fee"
                          rules={[{ required: true, message: 'Please input outlet online convenience fee!' }]}
                        >
                          <Input
                            type='number'
                            value={selectedOutlet.online_convenience_fee}
                            name="online_convenience_fee"
                            placeholder="Online Convenience Fee"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Service Charges"
                          rules={[{ required: true, message: 'Please input outlet service charges!' }]}
                        >
                          <Input
                            type='number'
                            value={selectedOutlet.service_charges}
                            name="service_charges"
                            placeholder="Service Charges"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Pet Pooja Id"
                          rules={[{ required: true, message: 'Please input outlet pet pooja id!' }]}
                        >
                          <Input
                            type='text'
                            value={selectedOutlet.extra_config?.petpooja_restaurant_id || ''}
                            name="petpooja_restaurant_id"
                            placeholder="Pet Pooja Id"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Posist Id"
                          rules={[{ required: true, message: 'Please input outlet posist id!' }]}
                        >
                          <TextArea
                            rows={3}
                            type='text'
                            value={selectedOutlet.extra_config?.posist_customer_key || ''}
                            name="posist_customer_key"
                            placeholder="Posist Id"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Repeat Order Notifications?"
                          rules={[{ required: true, message: 'Please input outlet repeat order notifications!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.extra_config?.repeat_order_notifications}
                            name="repeat_order_notifications"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Is Active?"
                          rules={[{ required: true, message: 'Please input outlet active!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.active}
                            name="active"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Is Brewery?"
                          rules={[{ required: true, message: 'Please input outlet brewery status!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.is_brewery}
                            name="is_brewery"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Has Dashboard Access?"
                          rules={[{ required: true, message: 'Please input outlet dashboard access!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.dashboard_access}
                            name="dashboard_access"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="On Brand Page?"
                          rules={[{ required: true, message: 'Please input outlet on brand page status!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.on_brand_page}
                            name="on_brand_page"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Loyalty Active?"
                          rules={[{ required: true, message: 'Please input outlet loyalty active status!' }]}
                        >
                          <Switch
                            checked={selectedOutlet.loyalty_active}
                            name="loyalty_active"
                            onChange={handleOutletChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Set Delivery Partner Preference"
                          rules={[{ required: true, message: 'Please input outlet delivery partner preference!' }]}
                        >
                          <Select
                            defaultValue={selectedOutlet?.extra_config?.delivery_partner_preference || 'dunzo'}
                            onChange={(value) => {
                              const e = {
                                target: {
                                  name: 'delivery_partner_preference',
                                  value: value,
                                }
                              };
                              handleOutletChanges(e);
                            }}
                            options={
                              [
                                {
                                  value: 'dunzo',
                                  label: 'Dunzo',
                                },
                                {
                                  value: 'porter',
                                  label: 'Porter',
                                }
                              ]
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
            {
              selectedOutlet && selectedOrderConfig &&
              <Row>
                <Col span={24}>
                  <h3>{selectedOutlet.name} Order Config Data</h3>
                  <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Use Custom Delivery?"
                          rules={[{ required: true, message: 'Please input outlet custom delivery status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.use_custom_delivery}
                            name="use_custom_delivery"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use Dine-In Ordering?"
                          rules={[{ required: true, message: 'Please input outlet on menu dinein status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.onMenuDineInActive}
                            name="onMenuDineInActive"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use In-Room Dining Ordering?"
                          rules={[{ required: true, message: 'Please input outlet on menu inroom dinein status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.onMenuInRoomActive}
                            name="onMenuInRoomActive"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use Paylater?"
                          rules={[{ required: true, message: 'Please input outlet use paylater status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.use_paylater}
                            name="use_paylater"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use Pay Now?"
                          rules={[{ required: true, message: 'Please input outlet use paynow status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.use_paynow}
                            name="use_paynow"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Hide Table No on Checkout?"
                          rules={[{ required: true, message: 'Please input outlet order config hide table no on checkout!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig?.extra_config?.hide_table_no_on_checkout}
                            name="hide_table_no_on_checkout"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use Dine-In Pay Now?"
                          rules={[{ required: true, message: 'Please input outlet use dinein paynow status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.use_dinein_pay_now}
                            name="use_dinein_pay_now"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Is Ordering Active?"
                          rules={[{ required: true, message: 'Please input outlet is ordering active status!' }]}
                        >
                          <Switch
                            checked={selectedOrderConfig.ordering}
                            name="ordering"
                            onChange={handleOrderConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
            {
              selectedOutlet && selectedMenuConfig &&
              <Row>
                <Col span={24}>
                  <h3>{selectedOutlet.name} Menu Config Data</h3>
                  <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Use Customer Register?"
                          rules={[{ required: true, message: 'Please input outlet customer register status!' }]}
                        >
                          <Switch
                            checked={selectedMenuConfig.use_customer_register}
                            name="use_customer_register"
                            onChange={handleMenuConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Use Long Item Prices?"
                          rules={[{ required: true, message: 'Please input outlet long item price status!' }]}
                        >
                          <Switch
                            checked={selectedMenuConfig.long_item_price}
                            name="long_item_price"
                            onChange={handleMenuConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Hide Footer?"
                          rules={[{ required: true, message: 'Please input outlet hide footer!' }]}
                        >
                          <Switch
                            checked={selectedMenuConfig.extra_config?.hide_footer}
                            name="hide_footer"
                            onChange={handleMenuConfigChanges}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Display Names"
                          rules={[{ required: true, message: 'Please input outlet display names!' }]}
                        >
                          <JSONInput
                            placeholder = { selectedMenuConfig.display_names }
                            theme = "dark_vscode_tribute"
                            height = "300px"
                            onChange={(data) => {
                              const e = {
                                target: {
                                  name: 'display_names',
                                  value: data.jsObject,
                                }
                              };
                              handleMenuConfigChanges(e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
            {
              outletLoginData &&
              <Row>
                <Col span={24}>
                  <h3>Outlet Login Data</h3>
                  <Form form={form} layout="vertical">
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item
                          label="Email"
                          rules={[{ required: true, message: 'Please input outlet login email!' }]}
                        >
                          <Input
                            type='text'
                            value={outletLoginData.email}
                            name="email"
                            placeholder="Email"
                            onChange={handleOutletLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Partner Id"
                          rules={[{ required: true, message: 'Please input outlet login partner id!' }]}
                        >
                          <Input
                            type='text'
                            value={outletLoginData.phone_number}
                            name="phone_number"
                            placeholder="Partner Id"
                            onChange={handleOutletLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Is Staff?"
                          rules={[{ required: true, message: 'Please input outlet is staff status!' }]}
                        >
                          <Switch
                            checked={outletLoginData.is_staff}
                            name="is_staff"
                            onChange={handleOutletLoginChanges}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <Form.Item shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={resetOutletPassword}
                              loading={isLoading}
                            >
                              RESET LOGIN PASSWORD
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
            {
              selectedBrand && brandShortName &&
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={updateAllData}
                    loading={isLoading}
                  >
                    SUBMIT
                  </Button>
                )}
              </Form.Item>
            }
          </>
        }
      </Space>
    </>
  );
}

export default Brand_Data;
