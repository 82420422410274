import { Switch, Route, Redirect } from "react-router-dom";
import Delivery from "./pages/Delivery";
import SignIn from "./pages/SignIn";
import Order_Info from "./pages/Order_Info";
import Brand_Data from "./pages/Brand";
import Outlet_Menu from "./pages/Outlet_Menu";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route exact path="/sign-in" component={SignIn} />
        <Main>
          <Route exact path="/deliveries" component={Delivery} />
          <Route exact path="/order_info" component={Order_Info} />
          <Route exact path="/brand_data" component={Brand_Data} />
          <Route exact path="/outlet_menu" component={Outlet_Menu} />
          <Redirect exact from="*" to="/deliveries" />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
