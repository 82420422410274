import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  LinkOutlined,
} from '@ant-design/icons';
import {
  Input,
  Form,
  Button,
  Space,
  notification,
  Row,
  Col,
} from 'antd';
import {
  getCustomerOrderDetails,
} from '../services/Orders';
import {
  getCustomerOrder,
  updateCustomerOrderAddress,
  updateUserDetails,
} from '../services/General';
import { OrderReceipt } from '../components/order_receipt';

import "../assets/styles/delivery.css";

const Order_Info = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [orderDetails, setOrderDetails] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [state, setState] = useState({});
  const componentRef = useRef();

  const getOrderDetails = () => {
    setIsLoading(true);
    getCustomerOrderDetails(orderId)
      .then(data => {
        setOrderDetails(data);
        setIsLoading(false);
        handlePrint();
      })
      .catch(_ => setIsLoading(false));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getOrderAddress = () => {
    setIsLoading(true);
    getCustomerOrder(orderId)
      .then(data => {
        const user = data.user;
        const address = data.user.address;
        setAddress(address);
        setState({
          ...state,
          formatted_address: address.formatted_address,
          latitude: address.latitude,
          longitude: address.longitude,
          house_no: address.house_no,
          landmark: address.landmark,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          user_id: user.id,
        });
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const updateAddress = () => {
    setIsLoading(true);
    updateCustomerOrderAddress({
      id: address.id,
      formatted_address: state.formatted_address,
      latitude: state.latitude,
      longitude: state.longitude,
      house_no: state.house_no,
      landmark: state.landmark,
    }).then(_ => {
      setAddress(undefined);
      setOrderId(0);
      setIsLoading(false);
    })
  }

  const updateCustomerDetails = () => {
    setIsLoading(true);
    updateUserDetails({
      id: state.user_id,
      first_name: state.first_name,
      last_name: state.last_name,
      email: state.email,
      phone_number: `+91${(state.phone_number || '').replace('+91', '')}`,
    }).then(_ => {
      setAddress(undefined);
      setOrderId(0);
      setIsLoading(false);
    })
  }

  return (
    <>
      <Space size="large" direction="vertical">
        <Form form={form} name="order_id" layout="inline">
          <Form.Item
            name="customer_order_id"
            rules={[{ required: true, message: 'Please input customer order id!' }]}
          >
            <Input
              type="number"
              placeholder="Order Id"
              onChange={(e) => setOrderId(e.target.value)}
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                disabled={
                  !orderId
                }
                onClick={getOrderAddress}
                loading={isLoading}
              >
                SEARCH
              </Button>
            )}
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                disabled={
                  !orderId
                }
                onClick={getOrderDetails}
                loading={isLoading}
              >
                PRINT BILL
              </Button>
            )}
          </Form.Item>
        </Form>
        {
          orderDetails &&
          <>
            <div style={{ display: "none" }}>
              <OrderReceipt order={orderDetails} ref={componentRef} />
            </div>
          </>
        }
        {
          address && orderId &&
          <Row>
            <Col span={24}>
              <h3>Address Details</h3>
              <Form form={form} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Form.Item
                      label="Formatted Address"
                      rules={[{ required: true, message: 'Please input formatted address!' }]}
                    >
                      <Input
                        type='text'
                        value={state.formatted_address}
                        name="formatted_address"
                        placeholder="Formatted Address"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Latitude"
                      rules={[{ required: true, message: 'Please input latitude!' }]}
                    >
                      <Input
                        type='number'
                        value={state.latitude}
                        name="latitude"
                        placeholder="Latitude"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Longitude"
                      rules={[{ required: true, message: 'Please input longitude!' }]}
                    >
                      <Input
                        type='number'
                        value={state.longitude}
                        name="longitude"
                        placeholder="Longitude"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="House No"
                    >
                      <Input
                        type='text'
                        value={state.house_no}
                        name="house_no"
                        placeholder="House No"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Landmark"
                    >
                      <Input
                        type='text'
                        value={state.landmark}
                        name="landmark"
                        placeholder="Landmark"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {
                  state.latitude && state.longitude &&
                  <Space size="large" style={{ 'marginBottom': '1.5rem'}} >
                    <a
                      href={
                        `https://www.google.com/maps/search/?api=1&query=${state.latitude},${state.longitude}`
                      }
                      target="_blank">
                      See order address on map
                      <LinkOutlined style={{ 'marginLeft': '0.5rem'}} />
                    </a>
                  </Space>
                }
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !state.formatted_address ||
                        !state.latitude ||
                        !state.longitude
                      }
                      onClick={updateAddress}
                      loading={isLoading}
                    >
                      UPDATE ADDRESS
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        }
        {
          address && orderId &&
          <Row>
            <Col span={24}>
              <h3>Customer Details</h3>
              <Form form={form} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Form.Item
                      label="First Name"
                      rules={[{ required: true, message: 'Please input customer first name!' }]}
                    >
                      <Input
                        type='text'
                        value={state.first_name}
                        name="first_name"
                        placeholder="First Name"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Last Name"
                      rules={[{ required: true, message: 'Please input customer last name!' }]}
                    >
                      <Input
                        type='text'
                        value={state.last_name}
                        name="last_name"
                        placeholder="Last Name"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Phone Number"
                      rules={[{ required: true, message: 'Please input customer phone number!' }]}
                    >
                      <Input
                        type='text'
                        value={state.phone_number}
                        name="phone_number"
                        placeholder="Phone Number"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Email Id"
                    >
                      <Input
                        type='text'
                        value={state.email}
                        name="email"
                        placeholder="Email Id"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !state.first_name ||
                        !state.phone_number ||
                        !state.email
                      }
                      onClick={updateCustomerDetails}
                      loading={isLoading}
                    >
                      UPDATE CUSTOMER DETAILS
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        }
      </Space>
    </>
  );
}

export default Order_Info;
