import api from './Api';

export const getCustomerOrderDetails = async (order_id) => {
   return Promise.all([
      api.get(
         `${process.env.REACT_APP_BASE_URL}order/admin/${order_id}/`,
      ),
      api.get(
         `${process.env.REACT_APP_BASE_URL}order/details/`,
         {
            params: {
               placed_order_id: order_id,
            }
         }
      ),
   ]).then(responses => {
      const order = Object.assign(
         responses[0].data,
         {
            details: responses[1].data,
            id: order_id,
         }
      );
      return order;
   });
};
